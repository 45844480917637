//$body-background-color: #fff;
//$card-background-color: rgba(0, 0, 0, 0.05);
$card-background-secondary-color: rgba(0, 0, 0, 0.09);
$text-color: #212529;
$white-text-color: #fff;
$card-label-color: #504d4d;
$card-value-color: #483b3c;
/* $side-bar-primary: #7386d5;
$side-bar-primary-active: #4557a7;
$side-bar-secondary: #6d7fcc;
$side-bar-secondary-active: #5167c5; */

$side-bar-primary:var(--primary-color);
$side-bar-primary-active:var(--primary-color);
$side-bar-secondary: var(--primary-color);
$side-bar-secondary-active:var(--primary-color);

$side-bar-text-color: #fff;
$header-background-color: #f5f5f5;
$border-color: #ced4da;

//------------ Variables for new UI
$body-background-color: rgb(234,238,242);
$card-background-color: #fff;
$card-heading-color: #4674FF;
$card-border-color: #dee2e6;
$modal-background-color: #fff;
$modal-title-color: #222222;
$card-active-border-color: #4557a7;

$primary-white-color: white;
$secondary-little-dark-white-color: rgba(245, 245, 2455, 1);
$silverish-white-color: rgba(250, 250, 250, 1);
$primary-low-opacity-white-color: rgba(255, 255, 255, 0.6);

$green-button-default-background-color: rgba(0, 170, 60, 0.8);
$green-button-hover-background-color: rgba(0, 170, 60, 1);

$blue-button-default-background-color: rgba(0, 115, 239, 0.8);
$blue-button-hover-background-color: rgba(0, 115, 239, 1);

$active-filter-sort-icon-color: rgba(0, 115, 239, 1);

$primary-faint-black-border-color: rgba(0, 0, 0, 0.1);


$primary-white-background-button-on-hover-background-color: rgba(0, 0, 0 ,0.05);

$primary-dark-green-text-color: rgba(0, 100, 50);

$primary-dark-black-box-shadow-color: rgba(0, 0, 0, 0.4);

$primary-blue-border-color: rgba(0, 115, 239, 0.1);
$secondary-darker-blue-border-color: rgba(0, 115, 239, 0.3);

$primary-black-icon-color: rgba(0, 0, 0, 0.5);

$primary-faint-black-color-text: rgba(0, 0, 0, 0.6);
$primary-faint-black-text-on-hover-color: rgba(0, 0, 0, 0.8);

$lightBlue: #373fa3;
$darkBlue: #0000ff;
$medBlue: #2525fae8;
$white:#ffffff;
$dim:#eaeef2;
$gry:#d7d7d7f5;
$medGry:#979494f5;
$lightGry:#b1afafce;
$darkGry: #a19c9c;
$line: #dee2e6;

$mobile-breakpoint: 481px;










