@mixin boxShadow( $shadow... ) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin transition( $transition... ) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin linearGradient( $position, $color1, $color2 ) {
  background: -webkit-linear-gradient(to $position, $color1, $color2);
  background: -o-linear-gradient(to $position, $color1, $color2);
  background: -moz-linear-gradient(to $position, $color1, $color2);
  background: linear-gradient(to $position, $color1, $color2);
}

@mixin keyframes( $name ) {
  @-webkit-keyframes #{ $name } {  @content;  }
  @-moz-keyframes #{ $name } {  @content; }
  @-o-keyframes #{ $name } { @content;  }
  @keyframes #{ $name } {  @content;  }
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin use-md-icon($icon){
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &::before {
  content: $icon;
  }
}